import {StatusResponse} from "@/types/types";
import {useState} from "react";

export type State = 'idle' | 'pending' | 'success' | 'error';

export const useSignAndSave = <T> (
  sign: (data: T) => Promise<string>,
  save: (data: T, signature: string) => Promise<StatusResponse>
) => {
  const [state, setState] = useState<State>('idle');
  const [error, setError] = useState<string>();

  const signAndSave = async (data: T) => {
    if (state === 'error') {
      setState('idle');
      setError(undefined);
    } else if (state !== 'idle') {
      return;
    }

    setState('pending');
    await sign(data)
      .then(s => save(data, s))
      .then(r => {
        if (r.success) {
          setState('success');
        } else {
          setError(r.message);
          setState('error');
        }
      })
      .catch(() => {
        setError('An error occurred');
        setState('error');
      })
      .finally(() => {
        setTimeout(() => {
          setState('idle');
          setError(undefined);
        }, 3000);
      })
  };

  return {
    signAndSave,
    state,
    error,
    isSuccess: state === 'success',
    isPending: state === 'pending',
    isError: state === 'error',
    isIdle: state === 'idle',
  };
}