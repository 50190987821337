import classNames from "classnames";
import {FaArrowTrendDown, FaArrowTrendUp} from "react-icons/fa6";
import {capitalizeFirstLetter} from "@/util/helpers";
import React from "react";
import {IoMdSwap} from "react-icons/io";
import {ClosePositionType} from "@/components/Perps/closePosition/ClosePositionView";

export type Props = {
  side: String;
  onChange: (side: ClosePositionType) => void;
}

export const closeSides = [
  {
    id: "market",
    label: "Market",
    icon: <IoMdSwap />
  },
  {
    id: "tp",
    label: "TP",
    icon: <FaArrowTrendUp />
  },
  {
    id: "sl",
    label: "SL",
    icon: <FaArrowTrendDown />
  }
]

export const ClosePositionSideSelect = ({ side, onChange }: Props) => {
  const renderSideButton = (value: ClosePositionType) => {
    const closeSide = closeSides.find((side) => side.id === value);
    return (
      <button
        onClick={() => value !== side && onChange(value)}
        className={classNames("capitalize flex flex-row gap-2 items-center justify-center flex-grow h-full font-light text-base", {
          "hover:bg-glass-focus": value === "market",
          "bg-glass-focus": value === side && value === "market",
          "hover:bg-call/50": value === "tp",
          "bg-call/50": value === side && value === "tp",
          "hover:bg-put/50": value === "sl",
          "bg-put/50": value === side && value === "sl",
        })}>
        {closeSide?.icon} {closeSide?.label.toUpperCase()}
      </button>
    )
  }

  return (
    <div id="limit-order-section-side"
         className="h-[65px] md:h-[50px] border-b border-neutral-content/50 grid grid-cols-3 items-center">
      {renderSideButton("market")}
      {renderSideButton("tp")}
      {renderSideButton("sl")}
    </div>
  );
}
