import {PerpPositionStatus} from "@/components/Perps/types";
import {formatUnits} from "viem";
import {formatEther} from "@/util/converters";

export const calculateCloseFee = (perpPositionStatus: PerpPositionStatus, netValue: number) => {
  const {position} = perpPositionStatus;
  const isLong = position.side === 'LONG';

  if (isLong) {
    return (position.principal + netValue) * position.feesToBePaid / (position.feesToBePaid + position.downPayment + position.principal);
  } else {
    return (position.collateralAmount + netValue) * position.feesToBePaid / (position.feesToBePaid + position.collateralAmount);
  }
}


export const calculateLimitPriceFromPnl = (perpPositionStatus: PerpPositionStatus, roi: number) => {
  const { position,  markPrice, token} = perpPositionStatus;
  if (roi === 0) {
    return markPrice;
  }
  const isLong = position.side === 'LONG';

  const interest = Number(formatUnits(perpPositionStatus.interestPaid, isLong ? 18 : token.decimals));

  const received = position.downPayment * (1 + roi / 100);
  const closeFee = position.feesToBePaid * received / position.downPayment;
  const netValue = received + closeFee;

  // const slippage = calculateSlippage(perpPositionStatus);
  if (isLong) {
    const payout = netValue + position.principal + interest;
    return payout / position.collateralAmount;
  } else {
    const payout = position.collateralAmount - netValue;
    return payout / (position.principal + interest);
  }
}

export const calculateSlippage = (perpPositionStatus: PerpPositionStatus) => {
  const {position, token, markPrice} = perpPositionStatus;
  const isLong = position.side === 'LONG';

  const interest = Number(formatUnits(perpPositionStatus.interestPaid, isLong ? 18 : token.decimals));
  const received = formatEther(BigInt(perpPositionStatus.netValue) + BigInt(perpPositionStatus.fee));
  let currentMarketExecutionPrice;
  if (isLong) {
    const payout = received + position.principal + interest;
    currentMarketExecutionPrice = payout / position.collateralAmount;
  } else {
    const payout = position.collateralAmount - received;
    currentMarketExecutionPrice = payout / (position.principal + interest);
  }
  const slippage = currentMarketExecutionPrice / markPrice;
  return slippage < 1 ? 1 / slippage : slippage;
}

export const calculateAmounts = (perpPositionStatus: PerpPositionStatus, limitPrice: number | undefined) => {
  if (!limitPrice || limitPrice < 0) {
    return {
      estimatedPnL: 0,
      estimatedPayout: 0,
      estimatedROI: 0,
      makerAmount: 0,
      takerAmount: 0,
    }
  }

  // const slippage = calculateSlippage(perpPositionStatus);

  const {position, token} = perpPositionStatus;
  const isLong = position.side === 'LONG';
  const interestPaid = Number(formatUnits(perpPositionStatus.interestPaid, isLong ? 18 : token.decimals));

  const makerAmount = position.collateralAmount;
  const takerAmount = isLong ? makerAmount * limitPrice : makerAmount / limitPrice;

  let netValue = isLong
    ? position.collateralAmount * limitPrice - position.principal - interestPaid
    : position.collateralAmount - (position.principal + interestPaid) * limitPrice;
  const closeFee = Math.max(0, netValue * position.feesToBePaid / (position.downPayment + position.feesToBePaid));
  const estimatedPayout = Math.max(0, netValue - closeFee);

  const estimatedPnL = estimatedPayout - position.downPayment;
  const estimatedROI = estimatedPnL / position.downPayment * 100;

  return {
    estimatedPnL,
    estimatedPayout,
    estimatedROI,
    makerAmount,
    takerAmount,
    closeFee
  };
};