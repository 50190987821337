import React from "react";
import { PerpConfig } from "@/components/Perps/types";
import { useHasMounted } from "@/hooks/useHasMounted";
import {useScreen} from "@/util/useScreen";
import {useModal} from "@/components/Modal";
import {AddressDisplay} from "@/components/Account/AddressDisplay";
import {IoChevronDown} from "react-icons/io5";
import {TokensTableModal} from "@/components/Perps/header/TokensTableModal";
import {FaCircleInfo} from "react-icons/fa6";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {isUsdToken} from "@/util/chainConstants";

export type Props = {
    perpConfig: PerpConfig;
    onChange: (perpConfig: PerpConfig) => void;
};

export const PerpTokenView = ({ perpConfig, onChange }: Props) => {
    const hasMounted = useHasMounted();
    const isDesktop = useScreen('sm');
    const modal = useModal();
    const isUsd = isUsdToken(perpConfig.token.address);

    if (!hasMounted) {
        return null;
    }

    return (
      <div className="">
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-row gap-2 items-center justify-between divide-neutral-content/30 hover:bg-gray-700 cursor-pointer rounded-md relative p-2"
                 onClick={modal.show}>
              <div className="flex flex-row gap-2 md:gap-4 items-center">
                <img src={perpConfig.token.imageUrl}
                     alt={perpConfig.token.symbol}
                     className="w-6 h-6 md:w-10 md:h-10 rounded-full border border-glass-focus" />
                {isDesktop ? (
                  <div className="flex flex-row items-center gap-2">
                    <span className="text-sm lg:text-xl">
                      { isUsd ? "ETH" : (perpConfig.token.symbol)}
                    </span>
                    <span className="text-neutral-content font-light text-xs md:text-sm">
                        { isUsd ? " / USDB" : (" / ETH")}
                      </span>
                  </div>
                ) : (
                  <div className="text-lg">
                    { isUsd ? "ETH" : (perpConfig.token.symbol)}
                    <span className="text-neutral-content font-light text-xs md:text-sm">
                      { isUsd ? " / USDB" : (" / ETH")}
                    </span>
                  </div>
                )}
              </div>
              <IoChevronDown />
            </div>
            {isDesktop && <AddressDisplay
              displayIcon={true}
              address={perpConfig.token.address}
              label="Block Explorer"
              className="text-neutral-content text-xs"
            />
            }
            {
              isDesktop && perpConfig.token.twitterHandle &&
              <a href={`https://twitter.com/${perpConfig.token.twitterHandle}`}
                 target="_blank"
                 rel="noopener noreferrer"
                 className="text-neutral-content text-xs bg-white/50 hover:bg-white p-1.5 rounded-full">
                <img src="/static/x_logo.png" className="w-2 h-2 md:w-3 md:h-3" alt={'twitter'}/>
              </a>
            }
            {
              isDesktop &&
              (
                perpConfig.token.address.toLowerCase() === "0xf7bc58b8d8f97adc129cfc4c9f45ce3c0e1d2692".toLowerCase() || // WBTC
                perpConfig.token.address.toLowerCase() === "0xd31a59c85ae9d8edefec411d448f90841571b89c".toLowerCase() // SOL
              ) &&
              <>
                <FaCircleInfo id="eth_btc_dominance" className="w-6 h-6 hover:scale-110" />
                <ReactTooltip
                  anchorSelect={`#eth_btc_dominance`}
                  id="tooltip_eth_btc_dominance"
                  place="bottom"
                  className="z-50 max-w-[300px]"
                  content={`${perpConfig.token.symbol}/ETH dominance pair has ETH as the base currency. Therefore, the chart and profits won't always correspond with ${perpConfig.token.symbol}/USD price movements.`}
                  style={{ backgroundColor: "#3b485f", color: "white", opacity: 1 }}
                />
              </>
            }
          </div>
          <TokensTableModal {...modal} onSelected={modal.hide} />
      </div>
    );
};
