import {useSignMessage, useSignTypedData} from "wagmi";
import {CancelOrder} from "@/components/Perps/types";
import {cancelOrder} from "@/api/perpsDataFetcher";
import {useSignAndSave} from "@/components/Perps/closePosition/useSignAndSave";

export const useCancelOrder = () => {
  const signMessage = useSignMessage();

  return useSignAndSave<CancelOrder>(
    (cancel) => {
      const message = `${cancel.positionId}_${cancel.orderType}_${cancel.createdAt}`;
      return signMessage.signMessageAsync({message});
    },
    cancelOrder);
}