import {useState} from "react";
import {useSignTypedData} from "wagmi";
import {TypedDataDomain} from "abitype/src/abi";
import {CHAIN_ID} from "@/util/constants";
import {setCloseLimitOrder} from "@/api/perpsDataFetcher";
import {ClosePositionOrder, PerpPositionStatus} from "@/components/Perps/types";
import {useSignAndSave} from "@/components/Perps/closePosition/useSignAndSave";



const types = {
  ClosePositionOrder: [
    {name: "orderType", type: "uint8"},
    {name: "positionId", type: "uint256"},
    {name: "createdAt", type: "uint256"},
    {name: "expiration", type: "uint256"},
    {name: "makerAmount", type: "uint256"},
    {name: "takerAmount", type: "uint256"},
    {name: "executionFee", type: "uint256"}
  ]
} as const;

export const useLimitOrderTransactionSign = (perpPositionStatus: PerpPositionStatus) => {
  const signTypedData = useSignTypedData();

  const domain: TypedDataDomain = {
    name: perpPositionStatus.position.side === "LONG" ? "WasabiLongPool" : "WasabiShortPool",
    version: '1',
    chainId: CHAIN_ID,
    verifyingContract: perpPositionStatus.position.poolAddress
  } as const;

  const sign = async (value: ClosePositionOrder) => {
    const updatedMessage = {
      orderType: value.orderType,
      positionId: BigInt(value.positionId.toString()),
      createdAt: BigInt(value.createdAt.toString()),
      expiration: BigInt(value.expiration.toString()),
      makerAmount: value.makerAmount,
      takerAmount: value.takerAmount,
      executionFee: value.executionFee,
    };

    return await signTypedData.signTypedDataAsync({
      domain,
      types,
      primaryType: "ClosePositionOrder",
      message: updatedMessage,
    });
  };

  return useSignAndSave<ClosePositionOrder>(sign, setCloseLimitOrder);
};
