import {CHAIN_ID} from "@/util/constants";
import {isUsdToken, WETH_ADDRESS} from "@/util/chainConstants";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {useEffect, useState} from "react";
import {useUserSelection} from "@/contexts/UserSelectionContext";
export interface Props {
    pairAddress: string;
    address: string;
}

export const DefinedChart = ({pairAddress, address} : Props) => {
    const {showInUsd} = useEthPrice();
    const {userSelections} = useUserSelection();
    const isUSDB = isUsdToken(address);
    const quoteAddress = WETH_ADDRESS;

    const urlParams = new URLSearchParams();

    if (isUSDB || BigInt(address) > BigInt(quoteAddress)) {
        urlParams.append("quoteToken", "token1");
    } else {
        urlParams.append("quoteToken", "token0");
    }

    urlParams.append("quoteCurrency", !isUSDB && showInUsd ? 'USD' : 'TOKEN');
    urlParams.append("embedded", "1");
    urlParams.append("hideTxTable", userSelections.showTxTable ? "0" : "1");
    urlParams.append("hideSidebar", userSelections.showSidebar ? "0" : "1");
    urlParams.append("hideChart", userSelections.showChart ? "0" : "1");
    urlParams.append("embedColorMode", "DEFAULT");

    const getSrc = () => `https://www.defined.fi/${CHAIN_ID === 1 ? "eth": "blast"}/${pairAddress}?${urlParams}`;
    const [src, setSrc] = useState(getSrc());

    useEffect(() => {
        setSrc(getSrc());
    }, [showInUsd, address]);

    return (
      <iframe
        key={`${pairAddress}-${address}-${showInUsd}-${userSelections.showChart}-${userSelections.showTxTable}-${userSelections.showSidebar}`}
        height="100%"
        width="100%"
        id="defined-embed"
        title="Defined Embed"
        src={src}
        frameBorder="0"
        allow="clipboard-write" />
    )
}
